<template>
  <div>
    <v-container>
      <v-row>

        <v-col v-if="data_cliente.code=== 'UPDATED'">
          <!--        <v-col >-->
          <div class="text-center text-success">
            <h3>Usuario activado con éxito</h3>
            <v-icon class="py-2" color="success" x-large>mdi-check-bold</v-icon>
            <p>{{ data_cliente.obj }}</p>
            <v-btn color="primary" to="/" small outlined>
              <v-icon small left>mdi-home</v-icon>
              <span>Ir al inicio</span></v-btn>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code=== 'MAIL_OK'">
          <!--                <v-col >-->
          <div class="text-center text-success">
            <h3>Nuevo correo de activación enviado</h3>
            <v-icon class="py-2" color="success" x-large>mdi-check-bold</v-icon>
            <p>{{ data_cliente.obj }}</p>
            <v-btn color="primary" to="/" small outlined>
              <v-icon small left>mdi-home</v-icon>
              <span>Ir al inicio</span></v-btn>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code=== 'NOT_MAIL'">
          <!--                          <v-col >-->
          <div class="text-center text-danger">
            <h3>No se pudo enviar el email de activación</h3>
            <v-icon color="error" class="py-2" x-large>mdi-close-thick</v-icon>
            <p>{{ data_cliente.obj }}</p>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code=== 'NOT_UPDATE_REGISTRO'">
          <!--        <v-col>-->
          <div class="text-center error--text">
            <h3>Error al generar código de activación</h3>
            <v-icon color="error" class="py-2" x-large>mdi-close-thick</v-icon>
            <p>{{ data_cliente.obj }}</p>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code=== 'NOT_UPDATED'">
          <!--                <v-col>-->
          <div class="text-center error--text">
            <h3>Datos no coincidentes</h3>
            <v-icon color="error" class="py-2" x-large>mdi-close-thick</v-icon>
            <p>{{ data_cliente.obj }}</p>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code=== 'CODIGO_NO_COINCIDE'">
          <!--        <v-col>-->
          <div class="text-center error--text">
            <h3>Código de activación inválido</h3>
            <v-icon color="error" class="py-2" x-large>mdi-close-thick</v-icon>
            <p>{{ data_cliente.obj }}</p>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code=== 'CODIGO_EXPIRADO'">
          <!--        <v-col>-->
          <div class="text-center error--text">
            <h3>El período de registro ha expirado</h3>
            <v-icon color="error" class="py-2" x-large>mdi-close-thick</v-icon>
            <p>{{ data_cliente.obj }}</p>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code=== 'CODIGO_UTILIZADO'">
          <!--        <v-col>-->
          <div class="text-center error--text">
            <h3>Código ya utilizado</h3>
            <v-icon color="error" class="py-2" x-large>mdi-close-thick</v-icon>
            <p>{{ data_cliente.obj }}</p>
          </div>
        </v-col>

        <v-col v-if="data_cliente.code === 'OPERACION_PROHIBIDA'">
          <div class="text-center">
            <h3 class="error--text">Error inesperado</h3>
            <v-icon color="error" class="py-2" x-large>mdi-close-thick</v-icon>
            <p>Ha ocurrido un error inesperado, favor comunicarse con AionSoft entregando la siguiente
              información:</p>
            <small>Rut: {{ rut }}</small><br>
            <small>Codigo: {{ codigo }}</small><br>
            <small>Motivo: {{ data_cliente.obj }}</small><br>
            <v-btn color="primary" to="/" small outlined class="mt-3">
              <v-icon small left>mdi-home</v-icon>
              <span>Ir al inicio</span></v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";

export default {
  name: "ActivacionView",
  data() {
    return {
      data_cliente: {},
      rut: null,
      codigo: null
    }
  },
  created() {
    console.log(this.$route.params.hola)

    this.rut = this.$route.params.rut_cliente;
    this.codigo = this.$route.params.codigo

    this.activarCliente(this.rut, this.codigo)

  },
  methods: {
    activarCliente(cliente, codigo) {
      usuarioEntity.updateActivacionCliente(cliente, codigo).then(res => {
        this.data_cliente = res;
      }).catch(error => {
        this.data_cliente = error;
      })
    },
    reenviarCorreo() {
      console.log("Reenviar")
    }
  },

}
</script>

<style scoped>

</style>